<template>
  <SfSection class="new-products">
    <template #heading>
      <div class="heading">
        <h2 class="sf-heading__title title">
          <span class="title-black">
            {{ $t('All the') }}
          </span>
          <span class="title-red">
            {{ $t('new-ins') }}
          </span>
        </h2>
      </div>
    </template>
    <SfLoader :class="{ loading }" :loading="loading">
      <div v-if="itemsInWishlist" class="products">
        <SfProductCardCustom
          v-for="(product, i) in mappedProducts"
          :key="i"
          class="product"
          :product-entity="product"
          :is-in-wishlist="!!itemsInWishlist[product.sku]"
          @clickWishlist="toggleWishlist(product, mappedProducts)"
        />
      </div>
    </SfLoader>
    <CustomButton
      icon="arrow_right"
      icon-color="var(--c-primary)"
      icon-position="right"
      :link="link"
      :rotate-arrow="true"
      :primary="false"
    >
      <span>{{ $t('Discover all new-ins') }}</span>
    </CustomButton>
  </SfSection>
</template>

<script>
import { SfLoader, SfSection } from '@storefront-ui/vue';
import { defineComponent, onMounted, ref } from '@nuxtjs/composition-api';
import {
  useProduct,
  productGetters,
  useUser,
  useCategorySearch,
} from '@gemini-vsf/composables';
import { useUrlHelpers, useWishlist } from '~/composables';
import SfProductCardCustom from '~/components/SfProductCardCustom.vue';
import CustomButton from '~/components/CustomButton.vue';

export default defineComponent({
  name: 'NewProducts',
  components: {
    SfSection,
    SfLoader,
    SfProductCardCustom,
    CustomButton,
  },
  props: {},
  setup() {
    const { buildProductUrl } = useUrlHelpers();
    const { isAuthenticated } = useUser();
    // eslint-disable-next-line @typescript-eslint/unbound-method
    const {
      search: getProductList,
      loading,
      products,
    } = useProduct('newProducts');
    const catUid = '65310298c5fdf86b8d562956';
    const mappedProducts = ref([]);
    const link = ref('');
    const { loadItemsFromList, itemsInWishlist, toggleWishlist } =
      useWishlist(mappedProducts);
    const { result: categorySearchResult, search: categorySearch } =
      useCategorySearch(`categorySearch:${catUid}`);

    onMounted(async () => {
      await getProductList({
        pageSize: 8,
        currentPage: 1,
        sort: {
          position: 'ASC',
        },
        filter: {
          category_uid: {
            eq: `grn:product-list:product-list::${catUid}`,
          },
        },
        customQuery: {
          products: 'simplifiedProductsListCustom',
        },
      });

      await categorySearch({
        filters: {
          category_uid: {
            eq: catUid,
          },
        },
        customQuery: {
          categorySearch: 'categoryUrl',
        },
      });

      link.value = categorySearchResult.value?.[0]?.url_path || 'novita';

      if (products.value?.items?.length) {
        mappedProducts.value = products.value.items;
        await loadItemsFromList(mappedProducts.value);
      }
    });

    return {
      isAuthenticated,
      loading,
      productGetters,
      mappedProducts,
      buildProductUrl,
      itemsInWishlist,
      toggleWishlist,
      link,
    };
  },
});
</script>

<style lang="scss">
.new-products {
  margin: 0 auto 8vw;

  .title {
    font-size: var(--font-size--ml);
    font-weight: var(--font-weight--semibold);

    .title-red {
      color: var(--c-primary);
    }
  }

  .products {
    display: flex;
    justify-content: space-between;
    flex-flow: row wrap;
    gap: 30px 20px;
  }

  .custom-button.secondary {
    width: max-content;
    margin: 5vw auto 0;

    .sf-icon-path {
      height: 1vw;
      width: 1vw;
    }
  }

  .custom-button.secondary:hover {
    .sf-icon-path {
      --icon-color: var(--c-white);
    }
  }
}

//desktop
@media (min-width: $desktop-xxl-min) {
  .new-products {
    max-width: var(--width-boxed);
    .products {
      gap: 50px 32px;
    }
  }
}

//small desktop
@media (min-width: $desktop-l-min) and (max-width: ($desktop-xxl-min - 1)) {
  .new-products {
    width: 80%;
    margin: 0 auto 12vw auto;

    .title {
      font-size: var(--font-size--m);
    }

    .custom-button.primary {
      width: max-content;
      font-size: var(--font-size--4xs);
      padding: 1vw;
    }

    .custom-button.secondary {
      .sf-icon-path {
        height: 1.5vw;
        width: 1.5vw;
      }
    }
  }
}

//tablet
@media (min-width: $tablet-min) and (max-width: $desktop-l-min - 1) {
  .new-products {
    margin: 0 var(--tablet-margin) 16vw;
    .sf-section__content {
      --section-content-margin: 50px 0 0 0;
    }

    .title {
      font-size: 30px;
      text-align: center;
    }

    .products {
      justify-content: space-around;
      column-gap: 40px;
    }

    .custom-button.secondary {
      width: max-content;
      max-width: 70vw;
      margin: 12vw auto 0;

      .sf-icon-path {
        height: 4vw;
        width: 4vw;
      }
    }

    .custom-button.primary {
      width: max-content;
      font-size: var(--font-size-4xs);
      padding: 1vw;
    }
  }
}

//mobile
@media (max-width: $mobile-max) {
  .new-products {
    margin: 0 var(--mobile-margin) 16vw;
    .sf-section__content {
      margin-top: 10vw;
    }

    .title {
      font-size: var(--font-size--sm);
      text-align: center;
    }

    .products {
      justify-content: space-around;
    }

    .custom-button.secondary {
      width: max-content;
      max-width: 70vw;
      margin: 12vw auto 0;

      .sf-icon-path {
        height: 4vw;
        width: 4vw;
      }
    }

    .custom-button.primary {
      font-size: var(--font-size--base);
    }
  }
}

@media (min-width: $tablet-min) and (max-width: 1023px) {
  .new-products {
    .products {
      .product-card {
        width: 45%;
        max-width: 45%;
        &-top {
          width: 100%;
        }
      }
    }
  }
}
@media (min-width: 1024px) and (max-width: 1439px) {
  .new-products {
    .products {
      .product-card {
        width: 21%;
        max-width: 21%;
        &-top {
          width: 95% !important;
        }
      }
    }
  }
}
</style>
