<template>
  <div
    class="hero-slider-banner-split-colors"
    :class="[
      uppercase && 'heading-uppercase',
      !splitWords && 'heading-one-word',
    ]"
  >
    <span class="heading-black-part">
      {{ blackPartIfFullSentence || blackPart }}</span
    >
    <span class="heading-red-part">{{ redPart || redPartIfFullSentence }}</span>
  </div>
</template>

<script>
import { defineComponent, ref, watchEffect } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'HeroSliderBannerSplitColors',
  components: {},
  props: {
    blackPart: {
      type: String,
      required: true,
    },
    redPart: {
      type: String,
      default: null,
    },
    uppercase: {
      type: Boolean,
      default: false,
    },
    splitWords: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const redPartIfFullSentence = ref(null);
    const blackPartIfFullSentence = ref(null);

    watchEffect(() => {
      if (!props.redPart) {
        redPartIfFullSentence.value = props.blackPart.split(' ').pop();
        const lastSpaceIndex = props.blackPart.lastIndexOf(' ');
        blackPartIfFullSentence.value = props.blackPart.slice(
          0,
          lastSpaceIndex
        );
      }
    });

    return {
      redPartIfFullSentence,
      blackPartIfFullSentence,
    };
  },
});
</script>

<style lang="scss" scoped>
.hero-slider-banner-split-colors {
  font-family: var(--font-family--primary);
  padding: 0 30px;
  &.heading-one-word {
    display: flex;
  }
  &.heading-uppercase {
    text-transform: uppercase;
  }
  .heading-black-part {
    color: black;
  }
  .heading-red-part {
    color: var(--c-primary);
  }
}
</style>
