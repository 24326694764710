<template>
  <div class="offers-banner-container">
    <div class="offers-banner-header">
      <p class="title">{{ $t(blockTitle) }}</p>
    </div>
    <div class="offers-banner">
      <div class="banner-images-wrapper">
        <div
          v-for="(offer, offerNumber) in blockData"
          :key="offerNumber"
          class="banner-item"
          :class="addClass(offerNumber)"
        >
          <OffersBannerImage
            v-if="offer && offer.images"
            :image-desktop="offer.images.desktop"
            :image-mobile="offer.images.mobile"
            :alt="offer.alt || `alt-offer.placeholder-${offerNumber}`"
            :link="offer.link"
            :button-label="offer.cta"
            :title="offer.title"
            :image-logo="offer.images.logo"
            :title-size="offer.titleSize"
            :position="offer.position"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OffersBannerImage from '@/components/OffersBanner/OffersBannerImage.vue';
import { contentGetters, useContent } from '@gemini-vsf/composables';
import { useI18n } from '@/helpers/hooks/usei18n';
import { ref, useFetch, useContext } from '@nuxtjs/composition-api';

export default {
  name: 'OffersBanner',
  components: {
    OffersBannerImage,
  },
  props: {},
  setup() {
    const { locale } = useI18n();
    const { blocks, loadBlocks } = useContent(`cmsBlockFooter-${locale}`);
    const offersBannerSelector = `offersBanner-${locale}`;
    const blockData = ref({});
    const { app } = useContext();
    const blockTitle = ref();

    useFetch(async () => {
      await loadBlocks({ identifiers: [offersBannerSelector] });
      const blocksContent = contentGetters.getCmsBlockContent(blocks.value);
      try {
        blockData.value =
          blocksContent[offersBannerSelector].OffersBlock.offers;
        blockTitle.value =
          blocksContent[offersBannerSelector].OffersBlock.title;
      } catch {
        console.warn('Could not load Offers Banner block data.');
      }
    });

    const addClass = (index) => {
      const imageClass = ['one', 'two', 'three', 'four'];
      return index >= imageClass.length ? '' : `image-${imageClass[index]}`;
    };

    return {
      addClass,
      blockData,
      blockTitle,
    };
  },
};
</script>

<style lang="scss">
.offers-banner-container {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: repeat(19, 2vw);
  padding-bottom: var(--spacer-2xl);
  background-color: var(--c-grey-1);
}

.offers-banner-header {
  display: grid;
  grid-column: 1/1;
  grid-row: 1/8;
  background-color: var(--c-red-1);
  width: 100vw;

  .title {
    font-family: var(--font-family--primary);
    font-weight: normal;
    font-size: var(--font-size--ml);
    line-height: var(--font-size--2xl);
    color: var(--c-white);
    text-align: right;
    margin-top: 3.5vw;
    padding-right: 55vw;
  }
}

.offers-banner {
  grid-column: 1/2;
  grid-row: 2/8;

  .banner-images-wrapper {
    display: grid;
    grid-template-columns: 50vw 16vw 16vw 12vw;
    grid-template-rows: repeat(10, 3.5vw);
    gap: 25px;

    .sf-image--placeholder {
      height: auto;
      width: 100%;
      object-fit: contain;
    }
    .offers-banner-image {
      position: relative;
    }
    .custom-button {
      position: absolute;
      bottom: -20px;
      left: 20px;
      padding: 10px 40px;
    }
  }
}

//desktop
@media (min-width: $desktop-xl-min) {
  .offers-banner {
    .banner-images-wrapper {
      .image-one {
        grid-column: 1/2;
        grid-row: 3/10;
        max-width: 46vw;
        justify-self: end;
        .sf-image {
          height: auto;
        }
        .custom-button {
          bottom: 21%;
        }
      }
      .image-two {
        grid-column: 2/3;
        grid-row: 1/5;
        justify-self: start;
        width: 100%;
        .custom-button {
          max-width: 60%;
        }
      }
      .image-three {
        grid-column: 3/5;
        grid-row: 1/5;
        justify-self: start;
        .custom-button {
        }
      }
      .image-four {
        grid-column: 2/5;
        grid-row: 5/10;
        justify-self: start;
        max-width: 35vw;
        .sf-image {
          height: auto;
        }
        .custom-button {
          bottom: 9%;
        }
      }
    }
  }
}

//small desktop
@media (min-width: $desktop-min + 1) and (max-width: $desktop-xl-min - 1) {
  .offers-banner-header {
    .title {
      font-size: var(--font-size--sm);
      line-height: var(--font-size--l);
    }
  }

  .offers-banner {
    .banner-images-wrapper {
      .custom-button {
        padding: 5px 20px;
        font-size: 8px;
      }
    }
  }

  .offers-banner {
    .banner-images-wrapper {
      .image-one {
        grid-column: 1/2;
        grid-row: 3/10;
        max-width: 46vw;
        justify-self: end;
        .sf-image {
          height: auto;
        }
        .custom-button {
          bottom: 28%;
          //top: 58%;
          //left: 10%;
        }
      }
      .image-two {
        grid-column: 2/3;
        grid-row: 1/5;
        justify-self: start;
        width: 100%;
        .custom-button {
          max-width: 60%;
          //top: 68%;
          //left: 8%;
        }
      }
      .image-three {
        grid-column: 3/5;
        grid-row: 1/5;
        justify-self: start;
        .custom-button {
          //top: 68%;
        }
      }
      .image-four {
        grid-column: 2/5;
        grid-row: 5/10;
        justify-self: start;
        max-width: 35vw;
        .custom-button {
          //top: 72%;
          //left: 6%;
        }
      }
    }
  }
}

//mobile + tablet
@media (max-width: $tablet-max + 1) {
  .offers-banner-container {
    grid-template-rows: repeat(40, 5vw);
    padding-bottom: var(--spacer-lg);
  }

  .offers-banner-header {
    grid-row: 1/13;
    grid-column: 1/2;

    .title {
      font-size: var(--font-size--s);
      text-align: center;
      margin-top: 7vw;
      padding: 0;
    }
  }

  .offers-banner {
    grid-row: 7/41;
    grid-column: 1/2;
    margin: 0 var(--mobile-margin);

    .banner-images-wrapper {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: auto;
      gap: 15px;

      &.banner-item {
        max-height: 200px;
      }

      .image-one {
        grid-column: 1/3;
        grid-row: 1/2;
      }

      .image-two {
        grid-column: 1/2;
        grid-row: 2/3;
      }

      .image-three {
        grid-column: 2/3;
        grid-row: 2/3;
      }

      .image-four {
        grid-column: 1/3;
        grid-row: 3/4;
      }
    }
  }
}
</style>
