<template>
  <div />
</template>

<script>
import { defineComponent, useMeta, ref } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'Organization',
  head: {},
  setup() {
    const structuredOrganizationData = ref({
      '@context': 'https://schema.org',
      '@type': 'Organization',
      url: `https://www.bestdigit.it/`,
      logo: `https://www.bestdigit.it/logo_bestdigit_small.png`,
      contactPoint: [
        {
          '@type': 'ContactPoint',
          telephone: '+39 06 9456060',
          email: 'assistenza@bestdigit.it',
          contactType: 'customer service',
        },
      ],
    });
    useMeta(() => ({
      script: [
        {
          innerHTML: JSON.stringify(structuredOrganizationData.value),
          type: 'application/ld+json',
        },
      ],
      __dangerouslyDisableSanitizers: ['script'],
    }));
  },
});
</script>
