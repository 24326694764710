<template>
  <div class="category-slider">
    <SfCarousel
      :settings="settings"
      v-if="mappedCategories.sections.links"
      ref="carousel"
    >
      <template #next="{ go }" v-if="isDesktop">
        <ArrowRightDark
          class="category-slider-arrow category-slider-arrow-right"
          alt="cs_arroe-right"
          @click="go('next')"
        />
      </template>
      <template #prev="{ go }" v-if="isDesktop">
        <ArrowLeftDark
          class="category-slider-arrow category-slider-arrow-left"
          alt="cs_arroe-left"
          @click="go('prev')"
        />
      </template>
      <SfCarouselItem
        v-for="(category, key) in mappedCategories.sections.links"
        :key="key"
      >
        <CategoryItem
          :title="category.label"
          :image="category.image"
          :link="category.url"
          :target="category.target"
        />
      </SfCarouselItem>
    </SfCarousel>
    <ul class="virtual-scrollbar with-indicator">
      <li
        :class="`scrollbar-item${k === currentItem ? ' is-active' : ''}`"
        v-for="(category, k) in mappedCategories.sections.links"
        :key="`cat-slider-nav-${k}`"
        :style="`width: ${100 / mappedCategories.sections.links.length}%`"
      >
        <span>{{ category.label }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
import { defineComponent, ref, useFetch } from '@nuxtjs/composition-api';
import { SfCarousel } from '@storefront-ui/vue';
import CategoryItem from '@/components/Category/CategoryItem.vue';
import { useI18n } from '@/helpers/hooks/usei18n';
import { contentGetters, useContent } from '@gemini-vsf/composables';
import { ArrowLeftDark, ArrowRightDark } from '~/static/icons';

export default defineComponent({
  name: 'CategorySlider',
  components: { CategoryItem, SfCarousel, ArrowRightDark, ArrowLeftDark },
  props: { isDesktop: Boolean },
  setup() {
    const translate = useI18n();
    const { locale } = translate;
    const settings = {
      type: 'carousel',
      rewind: true,
      perView: 3,
      slidePerPage: true,
      gap: 5,
      peek: {
        before: 0,
        after: 100,
      },
      breakpoints: {
        1919: {
          perView: 3,
          peek: {
            before: 0,
            after: 0,
          },
        },
        1439: {
          perView: 2,
          peek: {
            before: 0,
            after: 150,
          },
        },
        1199: {
          perView: 1,
          peek: {
            before: 0,
            after: 300,
          },
        },
        1023: {
          perView: 2,
          peek: {
            before: 0,
            after: 200,
          },
        },
        767: {
          perView: 1,
          peek: {
            before: 0,
            after: 200,
          },
        },
        449: {
          perView: 1,
          peek: {
            before: 0,
            after: 100,
          },
        },
      },
    };
    const currentItem = ref(0);

    const identifierImageCategories = `image-categories-${locale}`;

    const { blocks, loadBlocks } = useContent(
      `cmsBlockImagecategories-${locale}`
    );

    const mappedCategories = ref({
      sections: [],
    });

    useFetch(async () => {
      await loadBlocks({
        identifiers: [identifierImageCategories],
      });
      const blocksContent = contentGetters.getCmsBlockContent(blocks.value);
      // check if blocks contains the identifier
      mappedCategories.value = blocksContent[identifierImageCategories] ?? {
        sections: [],
      };
    });

    return {
      currentItem,
      settings,
      mappedCategories,
    };
  },
  mounted() {
    // check every second if this.$refs?.carousel?.glide exists, once the event is binded stop checking
    const interval = setInterval(() => {
      if (this.$refs?.carousel?.glide) {
        this.$refs.carousel.glide.on('run.after', () => {
          this.currentItem = this.$refs.carousel.glide.index;
        });
        clearInterval(interval);
      }
    }, 1000);
  },
});
</script>

<style lang="scss" scoped>
.category-slider {
  margin: 0 20px 150px 20px;

  .category-slider-skeletons {
    display: flex;
    gap: 10px;
    justify-content: space-around;
    .category-slider-skeleton {
      height: 630px;
      width: 306px;
    }
  }
  .category-slider-arrow {
    box-shadow: none;
    padding: 0;
    width: 44px;
    height: 44px;
    border: 0.5px solid var(--c-grey-2);
    border-radius: 3px;
    background-color: var(--c-white);
    transition: background-color 150ms;
    &:hover {
      background-color: var(--c-grey-9);
    }
  }

  // How many menu items do we have?
  $menu-items: 3;

  // Colours
  $background-color: var(--c-primary-darker);
  $indicator-color: var(--c-primary);

  // Transition Speed
  $transition-speed: 0.5s;

  // Dynamic Variables
  $width: calc(100 / $menu-items) * 1%; // makes each item the right size
  $menu-items-loop-offset: $menu-items - 1; // the number of items in the menu

  // ======================================================
  // Step 1 - Making a Fixed Width Navigation
  // ======================================================

  .virtual-scrollbar {
    min-height: 3px;
    background-color: $background-color;
    @extend %cf; // clear the floats
    list-style: none;
    margin: 50px auto 10px;
    max-width: 720px; // As all measurements are using % this could be a flexible value.
    padding: 0;
    width: 90%;
  }

  .scrollbar-item {
    display: block;
    float: left;
    margin: 0;
    padding: 0;
    width: $width; //dynamic width
    text-align: center;
    span {
      position: absolute;
      visibility: hidden;
    }

    &:first-child {
      border-radius: 3px 0 0 3px;
    }

    &:last-child {
      border-radius: 0 3px 3px 0;
    }

    &.is-active a {
      color: $indicator-color;
    }

    a {
      color: $background-color;
      display: block;
      padding-top: 20px;
      padding-bottom: 20px;
      text-decoration: none;

      &:hover {
        color: $indicator-color;
      }
    }
  }

  // ======================================================
  // Step 2 - Making the pseudo indicator
  // ======================================================

  .with-indicator {
    position: relative; // the menu is "relative" to the absolute position last-child pseudo elements.
    z-index: 0;

    .scrollbar-item {
      // ======================================================
      // Step 2.1 - Making the indicator with the pseudo element.
      // ======================================================
      &:last-child {
        &:before,
        &:after {
          content: '';
          display: block;
          position: absolute;
          pointer-events: none;
          transition: left #{$transition-speed} ease;
        }
        // Making the top CSS Triangle - learn more: https://css-tricks.com/animation-css-triangles-work/
        &:before {
          border: 3px solid transparent;
          border-top-color: $indicator-color;
          width: 0;
          height: 0;
          top: 0;
          left: calc($width/2);
          margin-left: -3px;
        }
        &:after {
          background: $indicator-color;
          top: -2px;
          bottom: -2px;
          left: 0;
          width: $width;
          z-index: -1;
        }
      }
    }

    @for $i from 1 through $menu-items-loop-offset {
      // when the Nav item "is active", make the indicator line up with the navigation item.
      .scrollbar-item:nth-child(#{$i}).is-active
        ~ .scrollbar-item:last-child:after {
        left: ($width * $i)-$width;
      }
      .scrollbar-item:nth-child(#{$i}).is-active
        ~ .scrollbar-item:last-child:before {
        left: ($width * $i) +
          calc(
            $width/2
          )-$width; // this ensures the triangle lines up to the menu.
      }
    }

    // ======================================================
    // Step 3 - Making it move
    // ======================================================

    // This could be in the same loop as the above but for the tutorial steps it's not
    @for $i from 1 through $menu-items-loop-offset {
      // We have to use !important to make the hovers overide if the last:child is the active or hovered
      .scrollbar-item:nth-child(#{$i}):hover
        ~ .scrollbar-item:last-child:after {
        left: ($width * $i)-$width !important;
      }

      .scrollbar-item:nth-child(#{$i}):hover
        ~ .scrollbar-item:last-child:before {
        left: ($width * $i) + calc($width/2)-$width !important;
      }
    }

    // this could be nested in the original .scrollbar-item
    .scrollbar-item {
      &:last-child {
        // make sure the last-child talks to itself
        &:hover,
        &.is-active {
          &:before {
            // we have to use important because the sibling selctor is more specific.
            left: (100%-$width) + calc($width/2) !important;
          }
          &:after {
            left: 100%-$width !important;
          }
        }
      }
    }
  }

  // ======================================================
  // The usual Global resets
  // ======================================================

  *,
  *:before,
  *:after {
    box-sizing: border-box; // learn more: https://css-tricks.com/box-sizing/
  }

  // Extending the https://css-tricks.com/micro-clearfix/
  %cf:before,
  %cf:after {
    content: ' ';
    display: table;
  }
  %cf:after {
    clear: both;
  }
}
@media (max-width: $tablet-max) {
  .category-slider {
    margin: 0 0 110px var(--mobile-margin);
  }
}

@media (min-width: $desktop-min) and (max-width: ($desktop-xxl-min - 1)) {
  .category-slider {
    .sf-carousel {
      --carousel-width: calc(80% + 40px);
    }
  }
}

@media (min-width: $desktop-xxl-min) {
  .category-slider {
    .sf-carousel {
      --carousel-width: var(--width-boxed);
    }
  }
}
</style>
