<template>
  <div class="categories-slider-wrapper">
    <div class="categories-slider">
      <h2 class="categories-carousel-title">
        <span class="title-first-part">{{ $t('Categorie ') }}</span>
        <span class="title-second-part">{{ $t('in evidenza') }}</span>
      </h2>
      <div v-if="Object.keys(blockData).length > 0">
        <SfCarousel :settings="settings" ref="carousel">
          <SfCarouselItem
            v-for="(card, key, idx) in blockData"
            :key="key + idx"
          >
            <div>
              <SfLink
                class="categories-carousel-item-wrapper"
                :link="getCategoryLink(card.category) || '/'"
                :target="card.target || '_self'"
              >
                <div
                  class="categories-carousel-image-container"
                  :class="card.background"
                >
                  <img
                    class="categories-carousel-image"
                    :src="card.image || getStaticIcon(idx)"
                    :alt="card.label"
                  />
                </div>
                <div class="categories-carousel-label-container">
                  <span class="categories-carousel-label">
                    {{ $t(card.label) }}
                  </span>
                </div>
              </SfLink>
            </div>
          </SfCarouselItem>
        </SfCarousel>
        <ul class="virtual-scrollbar with-indicator">
          <li
            :class="`scrollbar-item${idx === currentItem ? ' is-active' : ''}`"
            v-for="(card, key, idx) in blockData"
            :key="`cat-slider-nav-${idx}`"
            :style="`width: ${100 / 6}%`"
          >
            <span>{{ card.label }}</span>
          </li>
        </ul>
      </div>
      <div v-else class="categories-slider-skeletons">
        <SfSkeleton
          v-for="index in settings.perView"
          :key="index"
          type="image"
          class="categories-slider-skeleton-item"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, useFetch } from '@nuxtjs/composition-api';
import { SfCarousel, SfSkeleton, SfLink } from '@storefront-ui/vue';
import { contentGetters, useContent } from '@gemini-vsf/composables';
import { useI18n } from '@/helpers/hooks/usei18n';

export default defineComponent({
  name: 'FeaturedCategories',
  components: {
    SfCarousel,
    SfSkeleton,
    SfLink,
  },
  props: {},
  setup() {
    const currentItem = ref(0);

    const mockedCategories = [
      {
        label: 'Bonus 2022',
        icon: require('~/static/icons/stars.svg'),
        link: 'https://www.youtube.com/watch?v=dQw4w9WgXcQ',
        target: '_blank',
      },
      {
        label: 'Grandi elettrodomestici',
        icon: require('~/static/icons/fridge.svg'),
        link: 'https://www.youtube.com/watch?v=dQw4w9WgXcQ',
        target: '_blank',
      },
      {
        label: 'Piccoli elettrodomestici',
        icon: require('~/static/icons/standMixer.svg'),
        link: 'https://www.youtube.com/watch?v=dQw4w9WgXcQ',
        target: '_blank',
      },
      {
        label: 'Tv Audio Video',
        icon: require('~/static/icons/tv.svg'),
        link: 'https://www.youtube.com/watch?v=dQw4w9WgXcQ',
        target: '_blank',
      },
      {
        label: 'Fotografia',
        icon: require('~/static/icons/camera.svg'),
        link: 'https://www.youtube.com/watch?v=dQw4w9WgXcQ',
        target: '_blank',
      },
      {
        label: 'Casa e Ufficio',
        icon: require('~/static/icons/home.svg'),
        link: 'https://www.youtube.com/watch?v=dQw4w9WgXcQ',
        target: '_blank',
      },
    ];

    const settings = {
      type: 'carousel',
      rewind: true,
      perView: 6,
      slidePerPage: true,
      gap: 20,
      prev: {},
      next: {},
      controls: false,
      breakpoints: {
        1402: {
          perView: 5,
          peek: {
            before: 20,
            after: 50,
          },
        },
        1240: {
          perView: 4,
          peek: {
            before: 20,
            after: 50,
          },
        },
        1023: {
          perView: 3,
          peek: {
            before: 0,
            after: 50,
          },
        },
        640: {
          perView: 2,
          peek: {
            before: 0,
            after: 50,
          },
        },
        520: {
          perView: 1,
          peek: {
            before: 0,
            after: 200,
          },
        },
        400: {
          perView: 1,
          peek: {
            before: 20,
            after: 100,
          },
        },
      },
    };

    const { locale } = useI18n();
    const { blocks, loadBlocks } = useContent(
      `cmsBlockFeaturedCategories-${locale}`
    );
    const categoriesSelector = `featuredCategories-${
      locale === 'it' ? 'it' : 'en-US'
    }`;
    const blockData = ref({});

    useFetch(async () => {
      await loadBlocks({
        identifiers: [categoriesSelector],
      });
      const blocksContent = contentGetters.getCmsBlockContent(blocks.value);
      try {
        blockData.value = blocksContent[categoriesSelector];
      } catch {
        console.warn(
          'An error occurred loading featured categories block data.'
        );
      }
    });

    const getStaticIcon = (index) => mockedCategories[index].icon;
    const getCategoryLink = (url) =>
      url?.[0] === '/' ? `${locale}${url}` : `${locale}/${url}`;

    return {
      currentItem,
      mockedCategories,
      settings,
      blockData,
      categoriesSelector,
      getStaticIcon,
      getCategoryLink,
    };
  },
  mounted() {
    // check every second if this.$refs?.carousel?.glide exists, once the event is binded stop checking
    const interval = setInterval(() => {
      if (this.$refs?.carousel?.glide) {
        this.$refs.carousel.glide.on('run.after', () => {
          this.currentItem = this.$refs.carousel.glide.index;
        });
        clearInterval(interval);
      }
    }, 1000);
  },
});
</script>

<style lang="scss">
.categories-slider-wrapper {
  background: linear-gradient(
    180deg,
    var(--c-grey-1) calc(100% * (2 / 3)),
    var(--c-white) calc(100% * (1 / 3))
  );
  margin-bottom: 100px;
  .categories-slider {
    .categories-carousel-title {
      font-size: var(--font-size--ml);
      margin-bottom: 50px;
      padding-top: 15px;

      .title-first-part {
        color: var(--c-black);
      }

      .title-second-part {
        color: var(--c-primary);
      }
    }

    .categories-carousel-item-wrapper {
      max-width: 217px;
      width: inherit;
      text-decoration: none;

      .categories-carousel-image-container {
        height: 100px;
        width: inherit;
        background-color: var(--c-white);
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0 3px 15px var(--c-grey-7);
        //transition: background-color ease-out 300ms;

        &.background-gradient {
          background: var(--background-primary-gradient);
        }

        &.background-primary {
          background: var(--c-primary);
        }

        &.background-primary-darker {
          background: var(--c-primary-darker);
        }

        &.background-white {
          background: var(--c-white);
        }

        .categories-carousel-image {
          width: auto;
          height: 50%;
        }
      }

      .categories-carousel-label-container {
        max-width: 217px;
        display: flex;
        justify-content: center;
        margin-top: 15px;

        .categories-carousel-label {
          font-size: var(--font-size--2xs);
          font-weight: var(--font-weight-semibold);
          font-family: var(--font-family--primary);
          text-align: center;
        }
      }
    }

    & > div {
      .sf-carousel {
        .sf-carousel__wrapper {
          max-width: 100%;
          margin: 0;
        }
      }
    }
  }

  // How many menu items do we have?
  $menu-items: 6;

  // Colours
  $background-color: var(--c-primary-darker);
  $indicator-color: var(--c-primary);

  // Transition Speed
  $transition-speed: 0.5s;

  // Dynamic Variables
  $width: calc(100 / $menu-items) * 1%; // makes each item the right size
  $menu-items-loop-offset: $menu-items - 1; // the number of items in the menu

  // ======================================================
  // Step 1 - Making a Fixed Width Navigation
  // ======================================================

  .virtual-scrollbar {
    min-height: 3px;
    background-color: $background-color;
    @extend %cf; // clear the floats
    list-style: none;
    margin: 50px auto 10px;
    max-width: 720px; // As all measurements are using % this could be a flexible value.
    padding: 0;
    width: 90%;
  }

  .scrollbar-item {
    display: block;
    float: left;
    margin: 0;
    padding: 0;
    width: $width; //dynamic width
    text-align: center;
    span {
      position: absolute;
      visibility: hidden;
    }

    &:first-child {
      border-radius: 3px 0 0 3px;
    }

    &:last-child {
      border-radius: 0 3px 3px 0;
    }

    &.is-active a {
      color: $indicator-color;
    }

    a {
      color: $background-color;
      display: block;
      padding-top: 20px;
      padding-bottom: 20px;
      text-decoration: none;

      &:hover {
        color: $indicator-color;
      }
    }
  }

  // ======================================================
  // Step 2 - Making the pseudo indicator
  // ======================================================

  .with-indicator {
    position: relative; // the menu is "relative" to the absolute position last-child pseudo elements.
    z-index: 0;

    .scrollbar-item {
      // ======================================================
      // Step 2.1 - Making the indicator with the pseudo element.
      // ======================================================
      &:last-child {
        &:before,
        &:after {
          content: '';
          display: block;
          position: absolute;
          pointer-events: none;
          transition: left #{$transition-speed} ease;
        }
        // Making the top CSS Triangle - learn more: https://css-tricks.com/animation-css-triangles-work/
        &:before {
          border: 3px solid transparent;
          border-top-color: $indicator-color;
          width: 0;
          height: 0;
          top: 0;
          left: calc($width/2);
          margin-left: -3px;
        }
        &:after {
          background: $indicator-color;
          top: -2px;
          bottom: -2px;
          left: 0;
          width: $width;
          z-index: -1;
        }
      }
    }

    @for $i from 1 through $menu-items-loop-offset {
      // when the Nav item "is active", make the indicator line up with the navigation item.
      .scrollbar-item:nth-child(#{$i}).is-active
        ~ .scrollbar-item:last-child:after {
        left: ($width * $i)-$width;
      }
      .scrollbar-item:nth-child(#{$i}).is-active
        ~ .scrollbar-item:last-child:before {
        left: ($width * $i) +
          calc(
            $width/2
          )-$width; // this ensures the triangle lines up to the menu.
      }
    }

    // ======================================================
    // Step 3 - Making it move
    // ======================================================

    // This could be in the same loop as the above but for the tutorial steps it's not
    @for $i from 1 through $menu-items-loop-offset {
      // We have to use !important to make the hovers overide if the last:child is the active or hovered
      .scrollbar-item:nth-child(#{$i}):hover
        ~ .scrollbar-item:last-child:after {
        left: ($width * $i)-$width !important;
      }

      .scrollbar-item:nth-child(#{$i}):hover
        ~ .scrollbar-item:last-child:before {
        left: ($width * $i) + calc($width/2)-$width !important;
      }
    }

    // this could be nested in the original .scrollbar-item
    .scrollbar-item {
      &:last-child {
        // make sure the last-child talks to itself
        &:hover,
        &.is-active {
          &:before {
            // we have to use important because the sibling selctor is more specific.
            left: (100%-$width) + calc($width/2) !important;
          }
          &:after {
            left: 100%-$width !important;
          }
        }
      }
    }
  }

  // ======================================================
  // The usual Global resets
  // ======================================================

  *,
  *:before,
  *:after {
    box-sizing: border-box; // learn more: https://css-tricks.com/box-sizing/
  }

  // Extending the https://css-tricks.com/micro-clearfix/
  %cf:before,
  %cf:after {
    content: ' ';
    display: table;
  }
  %cf:after {
    clear: both;
  }
}
@media (max-width: $tablet-max) {
  .categories-slider-wrapper {
    .categories-slider {
      margin-left: var(--mobile-margin);
      .categories-carousel-title {
        text-align: center;
        font-size: var(--font-size--sm);
      }
    }
  }
}
@media (min-width: $desktop-min) {
  .categories-slider-wrapper {
    .categories-slider {
      .categories-carousel-title {
        margin-left: var(--tablet-margin);
      }

      .categories-carousel-item-wrapper {
        &:hover {
          .categories-carousel-image-container:not(.first-item) {
            background-color: var(--c-grey-8);

            .categories-carousel-image {
              filter: sepia(1) invert(1) contrast(15);
            }
          }
          .categories-carousel-image-container {
            &.background-gradient {
              background: var(--c-grey-8);

              .categories-carousel-image {
                filter: none;
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: $desktop-min) and (max-width: $desktop-xxl-min - 1) {
  .categories-slider-wrapper {
    .categories-slider {
      width: 80%;
      margin: 0 auto;
      .categories-carousel-title {
        font-size: var(--font-size--sm);
        margin-left: 0;
      }
    }
  }
}
@media (min-width: $desktop-xxl-min) {
  .categories-slider-wrapper {
    .categories-slider {
      max-width: var(--width-boxed);
      margin: 0 auto;
      .categories-carousel-title {
        margin-left: 0;
      }
    }
  }
}
</style>
