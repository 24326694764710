<template>
  <div class="category-item">
    <a :href="link" :target="target" class="category-item-link">
      <div
        class="category-item-image"
        :style="`background-image: url(` + image + `)`"
      ></div>
      <span class="title">{{ $t(title) }}</span>
    </a>
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';
import { addBasePath } from '@/helpers/addBasePath';

export default defineComponent({
  name: 'CategoryItem',
  props: {
    image: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    link: {
      type: String,
      default: '',
    },
    target: {
      type: String,
      default: '_self',
    },
  },
  setup() {
    return {
      addBasePath,
    };
  },
});
</script>

<style lang="scss" scoped>
.category-item {
  margin: 10px 0;
  .category-item-link {
    &:hover {
      .category-item-image {
        box-shadow: 0 0 0 2px var(--c-primary);
      }
    }
    position: relative;
    display: block;
    .category-item-image {
      border-radius: 10px;
      height: 300px;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
    .title {
      text-align: right;
      position: absolute;
      top: 45%;
      right: 10px;
      color: var(--c-white);
      font-size: var(--font-size--sm);
      font-weight: var(--font-weight-semibold);
      line-height: var(--font-size--2xl);
      z-index: 6;
    }
  }
}
//mobile
@media (max-width: $tablet-max) {
  .category-item {
    .category-item-link {
      .category-item-image {
        height: 160px;
      }
      .title {
        font-size: var(--font-size--xs);
        line-height: var(--font-size--ml);
      }
    }
  }
}
</style>
